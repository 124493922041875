var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "v-dialog"
    },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            title: _vm.title,
            visible: _vm.visible,
            width: _vm.width
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            },
            close: _vm.onClose
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                size: _vm.size,
                "label-width": _vm.labelWidth
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submit($event)
                }
              }
            },
            [
              _c("c-form-row", {
                attrs: { list: _vm.models, readOnly: _vm.readOnly },
                scopedSlots: _vm._u([
                  {
                    key: "jupUrl",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          _vm._b(
                            {},
                            "el-form-item",
                            scope.data.itemProps,
                            false
                          ),
                          [
                            _c("el-input", {
                              staticStyle: {
                                width: "220px",
                                "margin-right": "20px"
                              },
                              attrs: {
                                placeholder: "请输入内容",
                                value: _vm.projectName,
                                disabled: _vm.disabled
                              },
                              on: { input: _vm.changeValue },
                              model: {
                                value: _vm.form.jupUrl,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "jupUrl",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.jupUrl"
                              }
                            }),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleModel()
                                  }
                                }
                              },
                              [_vm._v("选择")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleShowInfo()
                                  }
                                }
                              },
                              [_vm._v("预览")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.form,
                  callback: function($$v) {
                    _vm.form = $$v
                  },
                  expression: "form"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.validateSubmit }
                },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      _vm.visible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ModelDialog", {
        ref: "ModelDialog",
        on: { Transmit: _vm.handleSelected }
      }),
      _c("ArticleDialog", {
        ref: "ArticleDialog",
        on: { Transmit: _vm.handleArticleSelected }
      }),
      _c("VideoDialog", {
        ref: "VideoDialog",
        on: { Transmit: _vm.handleVideoSelected }
      }),
      _c("ProjectExamineDialog", { ref: "ProjectExamineDialog" }),
      _c("ArticleExamineDialog", { ref: "ArticleExamineDialog" }),
      _c("VideoExamineDialog", { ref: "VideoExamineDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }