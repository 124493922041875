import "core-js/modules/es.date.to-string"; /*
                                             * @Description:
                                             * @Version: 1.0
                                             * @Autor: silencc
                                             * @Date: 2020-11-13 16:45:33
                                             * @LastEditors: Please set LastEditors
                                             * @LastEditTime: 2021-12-21 17:32:25
                                             */

/**
                                                 * ruleTypes: 规则类型
                                                 * genRules： rule 生成函数
                                                 * ctx： Vue实例
                                                 */
import { genModels } from '@/plugins/widget/c-form/rules';
import { eduLevel, payLevel, getType, lookStatus, advertLink } from '@/utils/options';

export var models = genModels(function (genRules, ctx) {return [
  {
    span: 18,
    kname: 'advertName',
    itemProps: {
      label: '名称：',
      rules: [genRules('require')] },

    component: {
      props: {
        maxlength: 20 } }


    /*showFn(val) {
                            //return ctx.editable
                            return val === ""
                          }*/ },

  {
    kname: 'imageUrl',
    itemProps: {
      label: '图片：' },

    component: {
      name: 'upload-image',
      props: {
        limit: 1,
        maxSize: 1024 } } },




  {
    kname: 'beginTime',
    itemProps: {
      label: '广告时间：',
      rules: [genRules('require')] },

    component: {
      name: 'c-date-picker',
      props: {
        clearable: true,
        type: 'datetime',
        format: 'yyyy-MM-dd HH:mm:ss',
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        pickerOptions: {
          disabledDate: function disabledDate(v) {
            if (v.valueOf() < new Date(new Date().toLocaleDateString()).valueOf()) {
              return true;
            }
            return false;
          } } } } },




  {
    kname: 'endTime',
    itemProps: {
      label: '至：',
      rules: [genRules('require')] },

    component: {
      name: 'c-date-picker',
      props: {
        clearable: true,
        type: 'datetime',
        format: 'yyyy-MM-dd HH:mm:ss',
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        pickerOptions: {
          disabledDate: ctx.showEndTime } } } },




  {
    kname: 'jupType',
    itemProps: {
      label: '设置跳转的类型：' },

    component: {
      name: 'selector',
      props: {
        filterable: true,
        list: advertLink
        // defaultValue: "请选择"
      },
      events: {
        change: ctx.changeSlected } } },



  {
    span: 20,
    kname: 'jupUrl',
    itemProps: {
      label: '选择跳转目标：' } }


  // {
  //  span:0,
  //   kname: 'jupId',

  // }
  ];});

// form 默认值
export var formData = function formData() {return {};};